import React from "react"
import Img from "gatsby-image"
import Col from "react-bootstrap/Col"
// import Badge from "react-bootstrap/Badge"
// import Card from "react-bootstrap/Card"

const ChannelCard = ({ channelFluidImage, channelName, channelIsHD }) => (
  <Col lg={2} md={3} sm={4} xs={6} className="modal-channels-channel p-2 m-2">
    {channelIsHD && (
      <span className="badge badge-warning tvtariff-channel-badge">HD</span>
    )}
    {channelFluidImage ? (
      <>
        <Img
          // fixed={channel.channelLogo.fixed}
          fluid={channelFluidImage}
          alt={channelName}
          title={channelName}
        />
        <div className="text-center mt-2">{channelName}</div>
      </>
    ) : (
      <div className="text-center mt-2">{channelName}</div>
    )}
    {/* {channel.category ? channel.category[0].category : ``} */}
  </Col>
)

ChannelCard.defaultProps = {
  channelFluidImage: ``,
  channelName: `Без назви`,
  channelIsHD: false,
}

export default ChannelCard
