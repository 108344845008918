import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import Layout from "../components/layout/basic"
import SEO from "../components/global/SEO"

import ChannelCard from "../components/tv/ChannelCard"

export const query = graphql`
  query($id: String!) {
    categories: allContentfulTvChannelCategory(
      filter: { node_locale: { eq: "uk" } }
      sort: { fields: orderId, order: ASC }
    ) {
      nodes {
        id
        category
        orderId
      }
    }
    tvtariff: contentfulTvTariff(id: { eq: $id }) {
      title
      id
      price
      seo {
        title
        metaDescription
        slug
      }
      channels {
        id
        channelName
        isHd
        category {
          category
          id
        }
        channelLogo {
          fluid(
            maxWidth: 128
            maxHeight: 80
            quality: 85
            resizingBehavior: PAD
          ) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      provider {
        name
        seo {
          slug
          pageImage {
            fluid(maxWidth: 620) {
              src
            }
          }
        }
        logo {
          fixed(width: 350, quality: 95) {
            ...GatsbyContentfulFixed_withWebp
          }
          fluid(maxHeight: 400, quality: 95, resizingBehavior: PAD) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`
const TvTariffTemplate = ({ data }) => {
  const Channels = data.tvtariff.channels
  return (
    <Layout>
      <SEO
        title={data.tvtariff.seo.title}
        description={data.tvtariff.seo.metaDescription}
        image={`https:${data.tvtariff.provider.seo.pageImage.fluid.src}`}
        url={`/telebachennya/${data.tvtariff.provider.seo.slug}/${data.tvtariff.seo.slug}/`}
      />
      <div className="hero-background-light">
        <Container className="py-5">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Newtelecom</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/telebachennya/">Телебачення</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to={`/telebachennya/${data.tvtariff.provider.seo.slug}/`}>
                  {data.tvtariff.provider.name}
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Тариф «{data.tvtariff.title}»
              </li>
            </ol>
          </nav>
          <Row>
            <Col lg={8} md={6} sm={12} className="tv-hero">
              <h1 className="gradient-text mt-auto">
                Тариф {data.tvtariff.title} {data.tvtariff.provider.name}
              </h1>
              <div className="hero-slogan mb-5">
                Перелік каналів в тарифі {data.tvtariff.title} від
                телепровайдера {data.tvtariff.provider.name}. Умови підключення,
                ціна та інструкції з налаштування.
              </div>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Img
                fixed={data.tvtariff.provider.logo.fixed}
                alt={`лого ${data.tvtariff.provider.name}`}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="my-5">
        <h2>Перелік каналів тарифа {data.tvtariff.title}</h2>
        <p>
          В тарифі «{data.tvtariff.title}» {data.tvtariff.channels.length}{" "}
          каналів, з яких{" "}
          {
            data.tvtariff.channels.filter(channel => channel.isHd === true)
              .length
          }{" "}
          в HD якості. Підключення безкоштовне, а щомісячна абонентська плата
          складає {data.tvtariff.price} грн.
        </p>
        {data.categories.nodes.map(category => {
          const categoryLength = Channels.filter(
            channel => channel.category[0].id === category.id
          ).length
          return (
            categoryLength > 0 && (
              <div key={category.id} className="my-5">
                <h3>{category.category}</h3>
                <Row className="py-3">
                  {Channels.filter(
                    channel => channel.category[0].id === category.id
                  ).map(ch => (
                    <ChannelCard
                      key={ch.id}
                      channelFluidImage={ch.channelLogo.fluid}
                      channelName={ch.channelName}
                      channelIsHD={ch.isHd}
                    />
                    // <li key={ch.id}>{ch.channelName}</li>
                  ))}
                </Row>
              </div>
            )
          )
        })}
      </Container>
    </Layout>
  )
}

export default TvTariffTemplate
